<script lang="ts" setup>
const { t } = useT();
</script>
<template>
	<div class="favorite-empty">
		<NuxtImg
			src="/nuxt-img/card-games/favorite-empty.png"
			width="104"
			height="104"
			webp="avif"
			loading="lazy"
			alt="favorite-empty"
		/>
		<AText :size="24" :modifiers="['bold']" as="h3">{{ t("Favorite games") }}</AText>
		<AText :size="16"
			>{{ t("Mark your favorites for quick access! Click the heart icon to the left on the play button.") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.favorite-empty {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	gap: 8px;
	padding-top: 8px;

	@include media-breakpoint-down(md) {
		padding-top: 0;
	}

	h3 {
		margin-bottom: 16px;
		color: var(--neutral-50);
	}

	span {
		max-width: 333px;
	}
}
</style>
